@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e2e38;
  line-height: 1.5;
  font-size: 16px;
  background: url(./assets/images/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}
body.modal-open {
  overflow: hidden;
}
body.payfine {
  background: #0f253a;
}
